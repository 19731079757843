// import CodemirrorThemes from './themes.js'
// const codemirror = r => require.ensure([], () => r(require('./VueCodemirror.vue')), 'codemirror-shared')

// export {
//   codemirror,
//   CodemirrorThemes
// }

import _CodeMirror from 'codemirror'
import codemirror from './VueCodemirror.vue'
import CodemirrorThemes from './themes.js'
// const codemirror = r => require.ensure([], () => r(require('./VueCodemirror.vue')), 'codemirror-shared')

const CodeMirror = window.CodeMirror || _CodeMirror
const install = (Vue, config) => {
  if (config) {
    if (config.options) {
      codemirror.props.globalOptions.default = () => config.options
    }
    if (config.events) {
      codemirror.props.globalEvents.default = () => config.events
    }
  }
  Vue.component(codemirror.name, codemirror)
}

const VueCodemirror = { CodeMirror, codemirror, install, CodemirrorThemes }

export default VueCodemirror
export { CodeMirror, codemirror, install, CodemirrorThemes }
